<template>
  <div class="loading_placeholder" :class="{ small: small }">
    <div class="load__animation"></div>
    <span v-if="text" v-html="text" />
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    text: {
      type: String,
      default: 'Daten werden geladen.'
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loading_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 1.3rem;

  .load__animation {
    border: 7px solid transparent;
    border-top: 7px solid $c-primary;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-sizing: content-box;
    animation: spin 1s linear infinite;
  }

  &.small {
    padding: 20px;

    .load__animation {
      width: 25px;
      height: 25px;
    }
  }

  span {
    margin-left: 10px;
  }
}
</style>
